
































































import WidgetMixins from '@/mixins/WidgetMixins';
import Component, { mixins } from 'vue-class-component';
import { loadWidget } from '@/utils/helpers';
import { namespace } from 'vuex-class';
import { Watch } from 'vue-property-decorator';
const authModule = namespace('auth');

@Component({
  name: 'AccountVerification',
  components: {
    SnackBar: loadWidget('widgets/SnackBar'),
    InternetConnection: loadWidget('pages/InternetConnection'),
    ProgressLoader: loadWidget('widgets/CircularProgressLoader'),
  },
})
export default class AccountVerification extends mixins(WidgetMixins) {
  @authModule.Getter('getIsAccountAlreadyVerified') isAccountAlreadyVerified!: {
    message: string;
    status: boolean;
  };

  accountURL: string = process.env.VUE_APP_REDIRECT_URL!;

  created(): void {
    if (this.$route.params.code) {
      this.$store.dispatch('auth/verifyAccount', {
        code: this.$route.params.code,
      });
    }
  }
}
